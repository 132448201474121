@import '../../vars';

.title {
  color: $secondary-color;
}
p {
  color: $text-color;
  margin: 0;
}

.container {
  margin: 0;
  flex: 1;
  height: 100vh;
  width: 100%;
  background-color: green;
}

.header {
  height: 12%;
  width: 100%;
  background-color: $primary-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 38px;
  padding-right: 38px;
  color: $secondary-color;
  font-size: 1.0em;
  font-weight: bold;
  span {
    font-size: 0.8em;
  }
}

.body {
  height: 88%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2%;
}

.button {
  width: 200px;
  height: 40px;
  border-radius: 5px;
  background-color: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 20px;
  border: 2px solid white;
  box-sizing: border-box;
}
.button:hover {
  cursor: pointer;
}
.button p {
  color: $secondary-color;
}
.notReadyButton {
  opacity: 0.4;
}
.leaveButton {
  color: darkred;
}

.settings {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}

.videos {
  display: none;
  position: relative;
  width: 100%;
  background-color: black;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 5px 5px 9px 1px rgba(0,0,0,0.3);
}

.subscriber {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.publisher {
  position: absolute;
  width: 250px;
  height: 160px;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: 5px 5px 9px 1px rgba(0,0,0,0.2);
  overflow: hidden;
}

.notConnected {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: rgba(darkred, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}
.notConnectedText {
  color: $secondary-color;
  font-size: 0.8em;
}

@media only screen and (max-width: 600px) {
  .header {
    font-size: 0.7em;
  }
  .body {
    flex-direction: column;
    align-items: center;
  }
  .settings {
    width: 90%;
  }
}
