@import '../../vars.scss';

.container {
  //position: absolute;
  //bottom: 10px;
  //left: 50%;
  //transform: translate(-50%);
  padding: 15px;
  height: 240px;
  width: 90%;
  border-radius: 5px;
  box-shadow: 5px 5px 9px 1px rgba(0,0,0,0.2);
  overflow-y: scroll;
  background-color: rgba($text-color, 1);
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.subtitles::-webkit-scrollbar {
  display: none;
}
.text {
  vertical-align: center;
  letter-spacing: 2px;
  line-height: 75px;
  font-size: 3.3rem;
  font-weight: 500;
}
.text:last-child {
  margin-bottom: 0;
}
.color0 {
  color: $subtitles-color;
}
.color1 {
  color: $text-color;
}
.reactMic {
  opacity: 0;
  width: 0;
  height: 0;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 90%;
    margin-bottom: 20px;
  }
}
